import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import LoginFirebase from "../components/login/loginFirebase"

const Login = ({location}) => {
  return(
    <Layout>
      <SEO title="Login" />
      <LoginFirebase location={location} />  
    </Layout>
  )  
}

export default Login
